<template>
  <div class="container-fluid mx-0 mx-md-0 px-0">
      <div class="bg-img-property">
        <p class="title text-white div-plan" data-aos="fade-up">
          <!-- {{textos.plan_precio_seccion_1_titulo}} -->
          {{ textos.titulo_prop_serv_seccion_1 }}
        </p>
      </div>
     <div>
      <div class="mb-2 py-5 my-2 div-3 container px-3">
        <div>
          <h2>{{ textos.subtitulo_prop_serv_seccion_1 }}</h2>
        </div>
        <div class="mt-4 div-3 px-md-0 px-md-5">
          <span>{{ textos.descripcion_prop_serv_seccion_1 }}</span>
        </div>
      </div>
      <div class="bg-img-servicios ">
        <p class="title text-white div-plan" data-aos="fade-up">
          <!-- {{textos.plan_precio_seccion_1_titulo}} -->
          {{ textos.titulo_prop_serv_seccion_2 }}
        </p>
      </div>
      <div class=" pt-5 mt-2 div-3 container px-3">
        
        <div class="">
          <span>{{ textos.descripcion_prop_serv_seccion_2 }}</span>
        </div>
      </div>
      <div id="Limpieza" class="pb-5  px-md-5 px-3  mx-md-5 ">
        <div class="row px-3">
          <card v-for="it1 in services_card_row_1" :key="it1.id" :data="it1" />
        </div>
        <div class="row px-3">
          <card v-for="it1 in services_card_row_2" :key="it1.id" :data="it1" />
        </div>
        <div class="row px-3">
          <card v-for="it1 in services_card_row_3" :key="it1.id" :data="it1" />
        </div>
      </div>
      
      <!-- <div class="pb-5 mt-3 px-md-5  mx-md-5">
        <div class="row">
          <div class="border-0 card col-md  mt-4 px-0">
            <img class="card-img-top mx-0 "  src="https://static.wixstatic.com/media/579837_b9bf3eac027147ef998254903f64e586~mv2.jpg/v1/fill/w_571,h_381,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/579837_b9bf3eac027147ef998254903f64e586~mv2.jpg">
            <div class="card-body bg-gray">
                <h4 class="card-title mb-4 mt-2 px-3">Mantenimiento de Casas, Villas y chalets , así como apartamentos y otros con precio mensual</h4>
                <p id="Nuestros_servicios" class="text-justify px-2">Mantenimiento de Casas, Villas y chalets , así como apartamentos y otros con precio mensual</p>
                
            </div>        
           
          </div>
          <div class="border-0 card col-md  mt-4 px-0">
            <img class="card-img-top mx-0 "  src="https://static.wixstatic.com/media/579837_62b1188f81234e02959ec9e8d13b7474~mv2.jpg/v1/fill/w_571,h_381,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/579837_62b1188f81234e02959ec9e8d13b7474~mv2.jpg">
            <div class="card-body bg-gray">
                <h4 class="card-title mb-4 mt-2 px-3">Servicio de manitas y arreglos</h4>
                <p id="Nuestros_servicios" class="text-justify px-2">Servicio de manitas y arreglos</p>
                
            </div>        
           
          </div>
          <div class="border-0 card col-md  mt-4 px-0">
            <img class="card-img-top mx-0 "  src="https://static.wixstatic.com/media/579837_42031de4558d4bf6b8472e6b2e2ca227~mv2.jpg/v1/crop/x_0,y_0,w_5175,h_3456/fill/w_571,h_380,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/579837_42031de4558d4bf6b8472e6b2e2ca227~mv2.jpg">
            <div class="card-body bg-gray">
                <h4 class="card-title mb-4 mt-2 px-3">Servicios de limpieza, lavanderia y ropa</h4>
                <p id="Nuestros_servicios" class="text-justify px-2">d</p>
                
            </div>        
           
          </div>
        </div>
      </div> -->
      <div class="bg-img-plam ">
        <p class="title text-white div-plan" data-aos="fade-up">
          <!-- {{textos.plan_precio_seccion_1_titulo}} -->
          {{ textos.titulo_prop_serv_seccion_3 }}
        </p>
      </div>
      <div id="Limpieza" class="pb-5 mt-3 px-md-5  mx-md-5 px-md-5 px-3">
        <div class="row px-3">
          <card v-for="it1 in plan_precio_card_seccion2" :key="it1.id" :data="it1" />
        </div>
        <div class="row px-3">
          <card v-for="it1 in plan_precio_card_seccion3" :key="it1.id" :data="it1" />
        </div>
        <div class="row px-3">
          <card v-for="it1 in plan_precio_card_seccion4" :key="it1.id" :data="it1" />
        </div>
      </div>
      <div class="mx-md-5 px-md-5 px-4 mb-5">
        <P>{{textos.plan_precio_seccion_5_text}}  <span class="text-primary pointer"><b>{{textos.plan_precio_seccion_5_text_link}}</b></span></P>
      </div>
     </div>
      <!-- <div class="container-fluid text-justify my-5 px-0 mx-0">
        <div class="container">
          <p class="ml-md-4 h2 "><b>{{ textos_sistem_colab.seccion_2_descripcion_sistem_colab }}</b></p>     
        </div>  
          <div class="container py-5">
            <div class="row ">
              <div class="col-1 mr-0 pr-0 mt-2 text-right">
                <b-icon icon="check-square" scale="2.5" class="mr-3 color-blue-sky "></b-icon>
              </div>
              <div class="col-11 pl-0 ml-0">
                <p class="my-2 pl-2 h3"><b>{{textos_sistem_colab.seccion_2_listItem_1_sistem_colab}}</b></p><br>
              </div>
            </div>
            <div class="row pl-md-5 pl-3">
              <div class="col-1 mr-0 pr-0 py-2 text-right mt-2">
                <b-icon icon="check2" scale="2.5" class="mr-3 color-blue-sky "></b-icon>
              </div>
              <div class="col-11 pl-0 ml-0">
                <p class="my-2 ml-3">{{textos_sistem_colab.seccion_2_listItem_2_sistem_colab}} </p><br>
              </div>
            </div>
            <div class="row pl-md-5 pl-3">
              <div class="col-1 mr-0 pr-0 py-2 text-right mt-2">
                <b-icon icon="check2" scale="2.5" class="mr-3 color-blue-sky "></b-icon>
              </div>
              <div class="col-11 pl-0 ml-0">
                <p class="my-2 ml-3">{{textos_sistem_colab.seccion_2_listItem_3_sistem_colab}}</p><br>
              </div>
            </div>
            <div class="row pl-md-5 pl-3">
              <div class="col-1 mr-0 pr-0 py-2 text-right mt-2">
                <b-icon icon="check2" scale="2.5" class="mr-3 color-blue-sky "></b-icon>
              </div>
              <div class="col-11 pl-0 ml-0">
                <p class="my-2 ml-3">{{textos_sistem_colab.seccion_2_listItem_4_sistem_colab}}</p><br>
              </div>
            </div>
            <div class="row pl-md-5 ml-md-5 pl-3 ml-3">
              <div class="col-1 mr-0 pr-0 py-2 text-right">
                <b-icon icon="circle-fill" scale="0.8"  class="mr-4 color-blue-sky"></b-icon>
              </div>
              <div class="col-11 pl-0 ml-0">
                <p class="my-2">{{textos_sistem_colab.seccion_2_listItem_5_sistem_colab}}</p><br>
              </div>
            </div>
            <div class="row pl-md-5 ml-md-5 pl-3 ml-3">
              <div class="col-1 mr-0 pr-0 py-2 text-right">
                <b-icon icon="circle-fill" scale="0.8" class="mr-4 color-blue-sky"></b-icon>
              </div>
              <div class="col-11 pl-0 ml-0">
                <p class="my-2">{{textos_sistem_colab.seccion_2_listItem_6_sistem_colab}}</p><br>
              </div>
            </div>
            <div class="row pl-md-5 ml-md-5 pl-3 ml-3">
              <div class="col-1 mr-0 pr-0 py-2 text-right">
                <b-icon icon="circle-fill" scale="0.8" class="mr-4 color-blue-sky"></b-icon>
              </div>
              <div class="col-11 pl-0 ml-0">
                <p class="my-2">{{textos_sistem_colab.seccion_2_listItem_7_sistem_colab}}</p><br>
              </div>
            </div>
          </div>
          <div class="bg-gray">
            <div class="container py-5">
              <div class="row ">
                <div class="col-1 mr-0 pr-0 mt-2 text-right">
                  <b-icon icon="check-square" scale="2.5" class="mr-3 color-blue-sky "></b-icon>
                </div>
                <div class="col-11 pl-0 ml-0">
                  <p class="my-2 pl-2 h3"><b>{{textos_sistem_colab.titulo_text_sistem_colab}}</b></p><br>
                </div>
              </div>
              <div class="row pl-md-5 pl-3">
                <div class="col-1 mr-0 pr-0 py-2 text-right mt-2">
                  <b-icon icon="check2" scale="2.5" class="mr-3 color-blue-sky "></b-icon>
                </div>
                <div class="col-11 pl-0 ml-0">
                  <p class="my-2 ml-3">{{textos_sistem_colab.seccion_2_listItem_8_sistem_colab}} </p><br>
                </div>
              </div>
            </div>
          </div>
          <div class="container py-5">
            <div class="row">
              <div class="col-1 mr-0 pr-0 mt-2 text-right">
                <b-icon icon="check-square" scale="2.5" class="mr-3 color-blue-sky "></b-icon>
              </div>
              <div class="col-11 pl-0 ml-0">
                <p class="my-2 pl-2  h3"><b>{{textos_sistem_colab.seccion_2_listItem_9_sistem_colab}}</b></p><br>
              </div>
            </div>
            <div class="row pl-md-5 pl-3">
              <div class="col-1 mr-0 pr-0 py-2 text-right mt-1">
                <b-icon icon="check2" scale="2.5" class="mr-3 color-blue-sky "></b-icon>
              </div>
              <div class="col-11 pl-0 ml-0">
                <p class="my-2 ml-3">{{textos_sistem_colab.seccion_2_listItem_10_sistem_colab}}</p><br>
              </div>
            </div>
          </div>
          
          
      </div> -->
  </div>
</template>

<script>

import card from "../components/card.vue";
export default {
  components: { card  },
  data(){
      return{
       
        
        textos:{},
        plan_precio_card_seccion2:[
        
        ],
        plan_precio_card_seccion3:[
        
        ],
        plan_precio_card_seccion4:[
        
        ],
        services_card_row_1:[
        
        ],
        services_card_row_2:[
        
        ],
        services_card_row_3:[
        
        ],
        
      }
    },
    async mounted(){
        this.$store.watch(
          (state) => [state.recarga].join(),
          async () => {
            // this.showItemsNumber();
            // this.refreshButton();
            this.textos = JSON.parse(localStorage.getItem('text'))
            await this.llenarCampos()
          }
        );
        
        this.textos = JSON.parse(localStorage.getItem('text'))
        console.log('textos en vista planes -->',this.textos)

        await this.llenarCampos()
      },
      methods:{
        
        async llenarCampos(){
          this.plan_precio_card_seccion2= []
          this.plan_precio_card_seccion3= []
          this.plan_precio_card_seccion4= []

          this.services_card_row_1= []
          this.services_card_row_2= []
          // this.services_card_row_3= []

          let plan_precio_seccion_2_card1 = {
            id:1,
            titulo: this.textos.plan_precio_seccion_2_card1_titulo,
            descripcion: this.textos.plan_precio_seccion_2_card1_descripcion,
            img: this.textos.plan_precio_seccion_2_card1_img,
            tipo: 'plan_precio',
            precio: this.textos.plan_precio_seccion_2_card1_precio
          }
          let plan_precio_seccion_2_card2 = {
            id:1,
            titulo: this.textos.plan_precio_seccion_2_card2_titulo,
            descripcion: this.textos.plan_precio_seccion_2_card2_descripcion,
            img: this.textos.plan_precio_seccion_2_card2_img,
            tipo: 'plan_precio',
            precio: this.textos.plan_precio_seccion_2_card2_precio
          }
          let plan_precio_seccion_2_card3 = {
            id:1,
            titulo: this.textos.plan_precio_seccion_2_card3_titulo,
            descripcion: this.textos.plan_precio_seccion_2_card3_descripcion,
            img: this.textos.plan_precio_seccion_2_card3_img,
            tipo: 'plan_precio',
            precio: this.textos.plan_precio_seccion_2_card3_precio
          }
          let plan_precio_seccion_3_card1 = {
            id:1,
            titulo: this.textos.plan_precio_seccion_3_card1_titulo,
            descripcion: this.textos.plan_precio_seccion_3_card1_descripcion,
            img: this.textos.plan_precio_seccion_3_card1_img,
            tipo: 'plan_precio',
            precio: this.textos.plan_precio_seccion_3_card1_precio
          }
          let plan_precio_seccion_3_card2 = {
            id:1,
            titulo: this.textos.plan_precio_seccion_3_card2_titulo,
            descripcion: this.textos.plan_precio_seccion_3_card2_descripcion,
            img: this.textos.plan_precio_seccion_3_card2_img,
            tipo: 'plan_precio',
            precio: this.textos.plan_precio_seccion_3_card2_precio
          }
          let plan_precio_seccion_3_card3 = {
            id:1,
            titulo: this.textos.plan_precio_seccion_3_card3_titulo,
            descripcion: this.textos.plan_precio_seccion_3_card3_descripcion,
            img: this.textos.plan_precio_seccion_3_card3_img,
            tipo: 'plan_precio',
            precio: this.textos.plan_precio_seccion_3_card3_precio
          }
          let plan_precio_seccion_4_card1 = {
            id:1,
            titulo: this.textos.plan_precio_seccion_4_card1_titulo,
            descripcion: this.textos.plan_precio_seccion_4_card1_descripcion,
            img: this.textos.plan_precio_seccion_4_card1_img,
            tipo: 'plan_precio',
            precio: this.textos.plan_precio_seccion_4_card1_precio
          }
          let plan_precio_seccion_4_card2 = {
            id:1,
            titulo: this.textos.plan_precio_seccion_4_card2_titulo,
            descripcion: this.textos.plan_precio_seccion_4_card2_descripcion,
            img: this.textos.plan_precio_seccion_4_card2_img,
            tipo: 'plan_precio',
            precio: this.textos.plan_precio_seccion_4_card2_precio
          }
          let plan_precio_seccion_4_card3 = {
            id:1,
            titulo: this.textos.plan_precio_seccion_4_card3_titulo,
            descripcion: this.textos.plan_precio_seccion_4_card3_descripcion,
            img: this.textos.plan_precio_seccion_4_card3_img,
            tipo: 'plan_precio',
            precio: this.textos.plan_precio_seccion_4_card3_precio
          }


          let services_card_row_1_card1 = {
            id:1,
            titulo: this.textos.services_card_row_1_card_1_titulo,
            // titulo: this.textos.plan_precio_seccion_2_card1_titulo,
            descripcion: this.textos.services_card_row_1_card_1_descripcion,
            img: this.textos.services_card_row_1_card_1_img,
            // tipo: 'plan_precio',
            
          }
          let services_card_row_1_card2 = {
            id:1,
            titulo: this.textos.services_card_row_1_card_2_titulo,
            // titulo: this.textos.plan_precio_seccion_2_card2_titulo,
            descripcion: this.textos.services_card_row_1_card_2_descripcion,
            img: this.textos.services_card_row_1_card_2_img,
            // tipo: 'plan_precio',
           
          }
          let services_card_row_1_card3 = {
            id:1,
            titulo: this.textos.services_card_row_1_card_3_titulo,
            // titulo: this.textos.plan_precio_seccion_2_card3_titulo,
            descripcion: this.textos.services_card_row_1_card_3_descripcion,
            img: this.textos.services_card_row_1_card_3_img,
            // tipo: 'plan_precio',
            
          }


          let services_card_row_2_card1 = {
            id:1,
            titulo: this.textos.services_card_row_2_card_1_titulo,
            // titulo: this.textos.plan_precio_seccion_3_card1_titulo,
            descripcion: this.textos.services_card_row_2_card_1_descripcion,
            img: this.textos.services_card_row_2_card_1_img,
            // tipo: 'plan_precio',
           
          }
          let services_card_row_2_card2 = {
            id:1,
            titulo: this.textos.services_card_row_2_card_2_titulo,
            // titulo: this.textos.plan_precio_seccion_3_card2_titulo,
            descripcion: this.textos.services_card_row_2_card_2_descripcion,
            img: this.textos.services_card_row_2_card_2_img,
            // tipo: 'plan_precio',
            
          }
          let services_card_row_2_card3 = {
            id:1,
            titulo: this.textos.services_card_row_2_card_3_titulo,
            // titulo: this.textos.plan_precio_seccion_3_card3_titulo,
            descripcion: this.textos.services_card_row_2_card_3_descripcion,
            img: this.textos.services_card_row_2_card_3_img,
            // tipo: 'plan_precio',
            
          }
          // let services_card_row_3_card1 = {
          //   id:1,
          //   titulo: this.textos.services_card_row_3_card_1_titulo,
          //   // titulo: this.textos.plan_precio_seccion_3_card1_titulo,
          //   descripcion: this.textos.services_card_row_3_card_1_descripcion,
          //   img: this.textos.services_card_row_3_card_1_img,
          //   // tipo: 'plan_precio',
          //   precio: this.textos.services_card_row_3_card_1_precio
          // }
          // let services_card_row_3_card2 = {
          //   id:1,
          //   titulo: this.textos.services_card_row_3_card_2_titulo,
          //   // titulo: this.textos.plan_precio_seccion_3_card2_titulo,
          //   descripcion: this.textos.services_card_row_3_card_2_descripcion,
          //   img: this.textos.services_card_row_3_card_2_img,
          //   // tipo: 'plan_precio',
          //   precio: this.textos.services_card_row_3_card_2_precio
          // }
          // let services_card_row_3_card3 = {
          //   id:1,
          //   titulo: this.textos.services_card_row_3_card_3_titulo,
          //   // titulo: this.textos.plan_precio_seccion_3_card3_titulo,
          //   descripcion: this.textos.services_card_row_3_card_3_descripcion,
          //   img: this.textos.services_card_row_3_card_3_img,
          //   // tipo: 'plan_precio',
          //   precio: this.textos.services_card_row_3_card_3_precio
          // }
          
        
          this.plan_precio_card_seccion2.push(plan_precio_seccion_2_card1)
          this.plan_precio_card_seccion2.push(plan_precio_seccion_2_card2)
          this.plan_precio_card_seccion2.push(plan_precio_seccion_2_card3)
          
          this.plan_precio_card_seccion3.push(plan_precio_seccion_3_card1)
          this.plan_precio_card_seccion3.push(plan_precio_seccion_3_card2)
          this.plan_precio_card_seccion3.push(plan_precio_seccion_3_card3)
          
          this.plan_precio_card_seccion4.push(plan_precio_seccion_4_card1)
          this.plan_precio_card_seccion4.push(plan_precio_seccion_4_card2)
          this.plan_precio_card_seccion4.push(plan_precio_seccion_4_card3)                       
          
          
          this.services_card_row_1.push(services_card_row_1_card1)
          this.services_card_row_1.push(services_card_row_1_card2)
          this.services_card_row_1.push(services_card_row_1_card3)

          this.services_card_row_2.push(services_card_row_2_card1)
          this.services_card_row_2.push(services_card_row_2_card2)
          this.services_card_row_2.push(services_card_row_2_card3)
          
          // this.services_card_row_3.push(services_card_row_3_card1)
          // this.services_card_row_3.push(services_card_row_3_card2)
          // this.services_card_row_3.push(services_card_row_3_card3)
          
          // console.log('cards seccion 3', this.cards_seccion_5)


        }
      }
}
</script>

<style>
.div-plan {
  padding-top: 190px;
  width: 66% !important;
  text-align: center !important;
  margin-left: 17%;
}
.bg-img-plam{
  /* The image used */
  background-image: url("/img/8.jpg");

  /* Set a specific height */
  height: 460px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-img-property{
  /* The image used */
  background-image: url("/img/property.jpg");

  /* Set a specific height */
  height: 460px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-img-servicios{
  background-image: url("/img/serv_integral.jpg");

  /* Set a specific height */
  height: 460px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>